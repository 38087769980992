import '../styles/pages/geolocation.scss'
import { searchSharp, searchOutline } from 'ionicons/icons'
import {
  useIonRouter,
  IonText,
  IonInput,
  IonPage,
  IonIcon,
  IonButton,
  IonItem,
  IonList,
  IonLabel
} from '@ionic/react'
import React, { useState, useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Geolocation } from '@capacitor/geolocation'
import { useLocation } from 'react-router-dom'
import { departements } from '../utils/cityList'
import type { City, Departement } from '../models/City'
import type { LatLngLiteral, LatLngExpression } from 'leaflet'
import { strParse } from '../utils/globalUtils'
import { useGetTenantQuery } from '../services/tenantService'
import type { Tenant } from '../models/Tenant'
import {
  setTenantList as sliceTenantListSet,
  setActiveTenant as sliceActiveTenantSet,
  setActiveTenant
} from '../features/tenantSlice'
import { updateMapState, updateUserLocation } from '../features/mapSlice'
import { setCSSInjected } from '../features/botSlice'
import * as turf from '@turf/turf'
import { HandleMultiplePolygon } from '../utils/gpsUtils'

const URL_API_ADDRESS =
  'https://api-adresse.data.gouv.fr/search/?q=%s&type=municipality&limit=100'

const defaultCity: City = {
  name: '',
  postcode: '',
  location: { lat: 0, lng: 0 }
}

const GetLocation = (): React.JSX.Element => {
  const dispatch = useDispatch()
  const router = useIonRouter()
  const url = useLocation()
  const [loading, setLoading] = useState<boolean>(false)
  const [currentCity, setCurrentCity] = useState<City>(defaultCity)
  const [filteredCitys, setFilteredCitys] = useState<City[]>([])
  const [inputIsDisabled, setInputIsDisabled] = useState(true)
  const [buttonIsDisabled, setButtonIsDisabled] = useState(true)
  const [isHidden, setIsHidden] = useState(true)
  const [depListIsHidden, setDepListIsHidden] = useState(true)
  const [depListDisable, setdepListDisable] = useState(false)
  const [selecteDepCode, setSelectedDepCode] = useState('0')
  const [message, setMessage] = useState('')
  const [displayName, setDisplayName] = useState('')
  const [departmentDisplayName, setDepartmentDisplayName] = useState('')
  const [tenants, setTenants] = useState<{ [key: string]: Tenant }>()
  const { data, error, isLoading } = useGetTenantQuery()

  const departmentOptions = (
    Array.from(new Set(departements.map((a) => a.code)))
      .map((code) => {
        return departements.find((a) => a.code === code)
      })
      .filter((a) => a !== undefined) as Departement[]
  ).sort((a, b) => a.name.localeCompare(b.name))

  useEffect(() => {
    dispatch(updateUserLocation({ currentUserLocation: undefined }))
    dispatch(setCSSInjected({ CSSInjected: false }))
    dispatch(setActiveTenant({ activeTenant: '' }))
    dispatch(
      updateMapState({
        mapState: { location: undefined, zoom: undefined }
      })
    )
    console.log('Reset Datas')
    setLoading(true)
  }, [])

  useEffect(() => {
    if (departmentOptions.length === 1) {
      setdepListDisable(true)
      setDepartment(departmentOptions[0].name, departmentOptions[0].code)
    } else {
      setdepListDisable(false)
    }
  }, [departmentOptions])

  useEffect(() => {
    console.log('useEffect data, isLoading', data, error)
    if (!isLoading && data && !error) {
      setTenants(data)
    } else if (error) {
      // temporary get mock tenants from error
      setTenants(error as { [key: string]: Tenant })
    }
  }, [data, isLoading])

  useEffect(() => {
    if (tenants !== undefined) {
      dispatch(sliceTenantListSet({ tenantList: tenants }))
    }
  }, [tenants])

  useEffect(() => {
    const getCurrentPosition = async (): Promise<void> => {
      Geolocation.getCurrentPosition()
        .then((res) => {
          const userLocation: LatLngExpression = {
            lat: res.coords.latitude,
            lng: res.coords.longitude
          }
          dispatch(updateUserLocation({ currentUserLocation: userLocation }))
          setTenant(userLocation, 'gps')
        })
        .catch((error) => {
          setTenant({ lat: 0, lng: 0 }, 'gps')
          console.log(error)
        })
    }
    if (tenants && Object.keys(tenants).length > 0) {
      getCurrentPosition()
    }

    if (selecteDepCode === '0') setInputIsDisabled(true)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenants])

  useEffect(() => {
    currentCity === defaultCity
      ? updateButtonLayout(false)
      : updateButtonLayout(true)
  }, [currentCity])

  useEffect(() => {
    if (selecteDepCode !== '0') setInputIsDisabled(false)
    resetCityInput()
    selecteDepCode !== '0'
      ? updateCityInputLayout(true)
      : updateCityInputLayout(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selecteDepCode])

  function setTenant(
    cityLocation: LatLngLiteral | undefined,
    method: string
  ): void {
    console.log(cityLocation, tenants)
    if (cityLocation !== undefined && tenants) {
      let tenantName = ''

      for (const [key, value] of Object.entries(tenants)) {
        const concavePolygonThin = turf.multiPolygon(
          [
            value.areaLocation.map((zone) => {
              const coords = zone.map((a) => [a[1], a[0]])
              // Close polygon
              if (coords.length > 0) {
                coords.push(coords[0])
              }
              return coords
            })
          ],
          { id: key }
        )
        const currentLoc = turf.point([
          parseFloat(cityLocation.lng + ''),
          parseFloat(cityLocation.lat + '')
        ])

        if (concavePolygonThin) {
          const boolIsIn = HandleMultiplePolygon(concavePolygonThin, currentLoc)
          console.log('concavePolygonThin', key, concavePolygonThin)
          if (boolIsIn) {
            console.log("Oui c'est dedans")
            tenantName = key
          } else {
            console.log('Non pas dedans')
          }
        }
      }

      if (tenantName !== '') {
        dispatch(updateUserLocation({ currentUserLocation: cityLocation }))
        dispatch(sliceActiveTenantSet({ activeTenant: tenantName }))
        dispatch(
          updateMapState({
            mapState: { location: undefined, zoom: undefined }
          })
        )
        resetCityInput()
        updateCityInputLayout(false)
        setInputIsDisabled(true)
        resetDepartmentInput()
        setMessage('')

        let tempTenantName = tenantName
        if (tempTenantName.indexOf('asklea_') > -1) {
          tempTenantName = tempTenantName.replaceAll('asklea_', '')
        }
        console.log('PUSH', tempTenantName)
        router.push('/' + tempTenantName)
      } else {
        const warningElement = document.getElementById('message')
        if (warningElement !== null) warningElement.style.display = 'inline'
        if (method === 'form') {
          setMessage(
            "Cette destination touristique n'est pas encore proposée par AskLéa. Veuillez sélectionner une autre ville."
          )
        } else if (method === 'gps' && url.pathname === '/get_location') {
          setMessage(
            'Vos coordonnées GPS ne nous permettent pas de vous orienter vers une destination touristique proposée par Askléa. \nVeuillez nous préciser une ville dans un département référencé.'
          )
        }
      }
      setLoading(false)
    }
  }

  function searchCity(input: string): void {
    let width = '320px'
    const cityInput = document.getElementById('city-input')

    if (cityInput !== null) {
      const coord = cityInput.getBoundingClientRect()
      const cityList = document.getElementById('city-list')
      width = cityInput.offsetWidth.toString() + 'px'

      const newTop = (coord.bottom + 5).toString() + 'px'

      if (cityList !== null) {
        cityList.style.top = newTop
        cityList.style.width = width
      }
    }

    setDisplayName(input)

    if (input.length > 2) {
      const url = strParse(URL_API_ADDRESS, input)
      fetch(url)
        .then((response) => response.json())
        .then((data) => {
          console.log(data)

          let dataToDisplay: City[] = []
          const gouvCitys = data.features
          for (let i = 0; i < gouvCitys.length; i++) {
            const tempCity = gouvCitys[i]

            if (tempCity.properties.context.includes(selecteDepCode)) {
              const tempObj: City = {
                name: tempCity.properties.city,
                postcode: tempCity.properties.postcode,
                location: {
                  lat: tempCity.geometry.coordinates[1],
                  lng: tempCity.geometry.coordinates[0]
                }
              }
              dataToDisplay.push(tempObj)
            }
          }
          dataToDisplay = dataToDisplay.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
          console.log(dataToDisplay)
          setFilteredCitys(dataToDisplay)
          setIsHidden(false)
        })
    } else {
      setFilteredCitys([])
    }
  }

  function updateCurrentCity(city: City): void {
    setCurrentCity(city)
    setDisplayName(city.name + ' (' + city.postcode + ')')
    setIsHidden(true)
    setFilteredCitys([])
  }

  function resetCityInput(): void {
    setCurrentCity(defaultCity)
    setDisplayName('')
    setFilteredCitys([])

    const warningElement = document.getElementById('message')
    if (warningElement !== null) warningElement.style.display = 'none'
  }

  function resetDepartmentInput(): void {
    setSelectedDepCode('0')
    setDepartmentDisplayName('')
  }

  function focusOutDepartment(): void {
    if (!depListIsHidden) {
      setDepListIsHidden(true)
      const departmentDiv = document.getElementById('department-input')
      if (departmentDiv !== null) departmentDiv.classList.remove('hasFocus')
    }
  }

  function updateButtonLayout(active: boolean): void {
    const button = document.getElementById('ionic-button')
    const buttonLabel = document.getElementById('button-label')

    if (button !== null && buttonLabel !== null) {
      if (active) {
        button.classList.add('ionic-button-enabled')
        button.classList.remove('ionic-button-disabled')
        buttonLabel.classList.add('button-label-enabled')
        buttonLabel.classList.remove('button-label-disabled')
        setButtonIsDisabled(false)
      } else {
        button.classList.remove('ionic-button-enabled')
        button.classList.add('ionic-button-disabled')
        buttonLabel.classList.remove('button-label-enabled')
        buttonLabel.classList.add('button-label-disabled')
        setButtonIsDisabled(true)
      }
    }
  }

  function updateCityInputLayout(active: boolean): void {
    const iconElement = document.getElementById('search-icon')
    const inputElement = document.getElementById('ion-city-input')

    if (iconElement !== null && inputElement !== null) {
      if (active) {
        iconElement.classList.add('icon-enabled')
        iconElement.classList.remove('icon-disabled')
        inputElement.classList.add('input-enabled')
        inputElement.classList.remove('input-disabled')
      } else {
        iconElement.classList.remove('icon-enabled')
        iconElement.classList.add('icon-disabled')
        inputElement.classList.remove('input-enabled')
        inputElement.classList.add('input-disabled')
      }
    }
  }

  function showDepartmentsList(): void {
    if (!depListDisable) {
      let width = '320px'

      const warningElement = document.getElementById('message')
      if (warningElement !== null) warningElement.style.display = 'none'

      const departmentInput = document.getElementById('ion-department-input')

      const departmentDiv = document.getElementById('department-input')
      if (departmentDiv !== null) {
        departmentDiv.classList.add('hasFocus')
        width = departmentDiv.offsetWidth.toString() + 'px'
      }

      if (departmentInput !== null) {
        const coord = departmentInput.getBoundingClientRect()
        const departmentList = document.getElementById('department-list')

        const newTop = (coord.bottom + 5).toString() + 'px'

        if (departmentList !== null) {
          departmentList.style.top = newTop
          departmentList.style.width = width
        }
      }

      setDepListIsHidden(!depListIsHidden)
    }
  }

  function setDepartment(name: string, code: any): void {
    setDepartmentDisplayName(name)
    setSelectedDepCode(code)
    setDepListIsHidden(true)

    const departmentDiv = document.getElementById('department-input')
    if (departmentDiv !== null) departmentDiv.classList.remove('hasFocus')
  }

  return (
    <IonPage id='get_geolocation' className='ion-text-xl-center wallpaper'>
      {loading && (
        <div className='loader-container'>
          <div className='loader' aria-hidden='true' />
        </div>
      )}
      <div id='message'>{message}</div>
      <div className='main-rectangle'>
        <div className='left-title'>Askléa</div>
        <div className='right-rectangle'>
          <div className='question'>
            <IonText>
              {depListDisable ? (
                <>
                  Quelle destination touristique de{' '}
                  <span>{departmentOptions[0].label}</span> voulez-vous explorer
                  ?
                </>
              ) : (
                `Quelle destination touristique voulez-vous explorer ?`
              )}
            </IonText>
          </div>
          {depListDisable ? (
            <></>
          ) : (
            <div className='department-select'>
              <div
                id='department-input'
                className={`${depListDisable ? 'disabled' : ''}`}
                onClick={(): void => showDepartmentsList()}>
                <IonInput
                  id='ion-department-input'
                  value={departmentDisplayName}
                  placeholder='Saisissez un département'
                  readonly
                  class='custom'
                  onBlur={(): void => focusOutDepartment()}
                />
                <IonIcon
                  className={`icon-enabled ${!depListIsHidden ? '' : 'active'}`}
                  id='arrow-down'
                  slot='end'
                />
              </div>
              <div id='department-list'>
                <IonList hidden={depListIsHidden} lines='none'>
                  {departmentOptions.map(
                    (department: Departement | undefined, i: number) => {
                      if (department) {
                        return (
                          <IonItem
                            key={i}
                            onMouseDown={(): void =>
                              setDepartment(department.name, department.code)
                            }>
                            <IonLabel>{department.name}</IonLabel>
                          </IonItem>
                        )
                      }
                      return undefined
                    }
                  )}
                </IonList>
              </div>
            </div>
          )}
          <div id='city-input'>
            <IonInput
              id='ion-city-input'
              disabled={inputIsDisabled}
              value={displayName}
              placeholder={
                depListDisable
                  ? `Saisissez une ville de ${departmentOptions[0].placeHolder}`
                  : `Saisissez une ville`
              }
              className='custom input-disabled'
              onIonInput={(e: any): void => searchCity(e.target.value)}
            />
            {currentCity === defaultCity ? (
              <IonIcon
                className='icon-disabled'
                id='search-icon'
                slot='end'
                ios={searchOutline}
                md={searchSharp}
              />
            ) : (
              <span className='close' onClick={(): void => resetCityInput()} />
            )}
          </div>
          <div id='city-list'>
            <IonList hidden={isHidden} lines='none'>
              {filteredCitys.map((city: any, i: number) => (
                <IonItem
                  key={i}
                  onMouseDown={(): void => updateCurrentCity(city)}>
                  <IonLabel>{city.name + ' (' + city.postcode + ')'}</IonLabel>
                </IonItem>
              ))}
            </IonList>
          </div>
          <div className='button-section'>
            <IonButton
              disabled={buttonIsDisabled}
              id='ionic-button'
              className='ionic-button-disabled '
              onClick={(): void => setTenant(currentCity?.location, 'form')}>
              <div id='button-label' className='button-label-disabled'>
                Valider
              </div>
            </IonButton>
          </div>
        </div>
      </div>
    </IonPage>
  )
}

export default GetLocation
