import {
  useIonRouter,
  IonContent,
  IonPage,
  IonModal,
  IonButton
} from '@ionic/react'
import React, { useEffect, useState } from 'react'
import type { Tenant } from '../models/Tenant'
import { Bot } from '../components/Bot'
import '../styles/pages/home.scss'
import { Geolocation } from '@capacitor/geolocation'

import { useGetTenantQuery } from '../services/tenantService'
import {
  setTenantList as sliceTenantListSet,
  setActiveTenant as sliceActiveTenantSet
} from '../features/tenantSlice'
import { useDispatch, useSelector } from 'react-redux'
import type { RootState } from '../stores/globalStore'
import { BotCustomEventListenner } from '../components/CustomEventListenner'
import { isMarkerInsidePolygon } from '../utils/gpsUtils'

interface HomeProps {
  tenantName: string
}

const Home = ({ tenantName }: HomeProps): React.JSX.Element => {
  const dispatch = useDispatch()
  const router = useIonRouter()
  const [showModal, setShowModal] = useState(false)
  const [period, setPeriod] = useState<number>(60000)
  const [newTenantName, setNewTenantName] = useState<string>('')

  const [activeTenant, setActiveTenant] = useState<string>(
    useSelector((state: RootState) => state.tenant.activeTenant)
  )
  const [tenantList, setTenantList] = useState<{ [key: string]: Tenant }>(
    useSelector((state: RootState) => state.tenant.tenantList)
  )
  const {
    data: tenants,
    error: errorTenants,
    isLoading: isLoadingTenants
  } = useGetTenantQuery()

  useEffect(() => {
    if (!isLoadingTenants && tenants && !errorTenants) {
      setTenantList(tenants)
    } else if (errorTenants) {
      // temporary get mock tenants from error
      setTenantList(errorTenants as { [key: string]: Tenant })
    }
  }, [tenants, isLoadingTenants])

  useEffect(() => {
    if (tenantList !== undefined) {
      dispatch(sliceTenantListSet({ tenantList: tenantList }))
    }
  }, [tenantList])

  useEffect(() => {
    if (activeTenant === '') {
      setActiveTenant(tenantName)
      dispatch(sliceActiveTenantSet({ activeTenant: tenantName }))
    }

    const interval = setInterval(() => {
      const getCurrentPosition = async (): Promise<void> => {
        Geolocation.getCurrentPosition()
          .then((res) => {
            let temp = ''

            for (const [key, value] of Object.entries(tenantList)) {
              if (
                isMarkerInsidePolygon(
                  { lat: res.coords.latitude, lng: res.coords.longitude },
                  value.areaLocation
                )
              ) {
                temp = key
              }
            }

            if (temp !== tenantName && temp !== '') {
              // new loc different from current loc
              setNewTenantName(temp)
              setShowModal(true)
            }
          })
          .catch((error) => {
            console.log(error)
          })
      }

      getCurrentPosition()
    }, period)

    return (): void => clearInterval(interval)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, tenantList])

  function dismiss(): void {
    setPeriod(7200000) // set modal prompt in 2hours
    setShowModal(false)
  }

  function confirm(): void {
    if (newTenantName !== '' && newTenantName !== undefined) {
      // tenantContext.setTenant(tenants[newTenantName])
      dispatch(sliceActiveTenantSet({ activeTenant: newTenantName }))
      // localStorage.setItem('tenant', JSON.stringify(tenants[newTenantName]))
      let tempTenantName = newTenantName
      if (tempTenantName.indexOf('asklea_') > -1) {
        tempTenantName = tempTenantName.replaceAll('asklea_', '')
      }
      router.push('/' + tempTenantName)
      setShowModal(false)
    }
  }

  return (
    <>
      {/* <Menu /> */}
      <IonPage id='main-content'>
        <IonModal is-open={showModal} backdrop-dismiss={false}>
          <p className='modal-question'>
            Un changement de territoire a été détecté. <br />
            Souhaitez-vous mettre à jour la zone touristique ?
          </p>
          <div>
            <IonButton
              className='confirm'
              fill='solid'
              onClick={(): void => confirm()}>
              Oui
            </IonButton>
            <IonButton
              className='dismiss'
              fill='outline'
              onClick={(): void => dismiss()}>
              Non
            </IonButton>
          </div>
        </IonModal>
        <IonContent class='map-container'>
          <BotCustomEventListenner />
          {Object.keys(tenantList).length && activeTenant && (
            <Bot tenant={tenantList[activeTenant].retorikName} />
          )}
        </IonContent>
      </IonPage>
    </>
  )
}

export default Home
